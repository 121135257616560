window.env = Object.freeze({
  ENV_NAME: import.meta.env.VITE_ENV_NAME,
  PW_AUTH_CSRF: import.meta.env.VITE_PW_AUTH_CSRF,
  PW_CSRF_TOKEN: import.meta.env.VITE_PW_CSRF_TOKEN,
  DEPLOYMENT_BASE_URL: import.meta.env.VITE_DEPLOYMENT_BASE_URL,
  COMMIT_SHA: __COMMIT_SHA___,
  HOTJAR_ID: import.meta.env.VITE_HOTJAR_ID,
  HOTJAR_VERSION: import.meta.env.VITE_HOTJAR_VERISON,
  monolith: {
    BASE_URL: import.meta.env.VITE_MONOLITH_BASE_URL,
    PAYER_HELP_URL: import.meta.env.VITE_MONOLITH_PAYER_HELP_URL,
    PAYEE_HELP_URL: import.meta.env.VITE_MONOLITH_PAYEE_HELP_URL,
  },
  reporting: {
    API_URL: import.meta.env.VITE_REPORTING_API_URL,
  },
});

const bootstrap = import("./bootstrap");
export default bootstrap;
